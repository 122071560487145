<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model :model="form" ref="newsCategoryForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
        :class="{ disabled: form.status == 2 }">
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="申请部室" prop="orgId">
              <a-cascader :options="orgs" v-model="form.orgId" @change="orgChanged" :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children',
              }" placeholder="请选择部室" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请日期" prop="applyTime">
              <a-date-picker v-model="form.applyTime" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请人" prop="applyUserName">
              <a-input-search class="select_search" readOnly placeholder="请选择" v-model="form.applyUserName"
                @click="onSearch" @search="onSearch">
                <a-button type="primary" slot="enterButton" icon="plus" title="添加" />
              </a-input-search>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-table bordered rowKey="id" :columns="columns" :data-source="form.items" :pagination="false"
          :locale="{ emptyText: '暂无数据' }">
          <span slot="textbox" slot-scope="text, record, index, column">
            <a-input v-model="record[column.dataIndex]" />
          </span>
          <span slot="count" slot-scope="text, record, index, column">
            <a-input-number v-model="record[column.dataIndex]" />
          </span>
          <span slot="category" slot-scope="data">
            <a-select v-model="data.category">
              <a-select-option v-for="item in assetcategory" :value="item.itemValue" :key="item.itemValue">{{
                item.itemName }}</a-select-option>
            </a-select>
          </span>
          <span slot="action" slot-scope="text, row, index">
            <a-popconfirm title="确定要删除吗？" @confirm="removeItem(index)">
              <a-button size="small" type="link" v-if="form.status == 0 || form.status == 3">删除</a-button>
            </a-popconfirm>
          </span>
          <template slot="footer" v-if="form.status == 0 || form.status == 3">
            <a-button @click="addItem">
              <a-icon type="plus" />
              新增</a-button>
          </template>
        </a-table>

      </a-form-model>
      <div style="text-align: center; margin-top: 10px;">
        <a-button v-if="form.status == 0 || form.status == 3" type="primary" @click="handleSubmit(0)"
          style="margin-right: 10px">保存</a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)" v-if="form.status == 0 || form.status == 3">
          <a-button type="danger" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <a-button type="primary" v-if="form.processExecuteStatus" style="margin-right: 10px"
          @click="showaudit()">审批</a-button>
        <a-button type="primary" v-if="form.status == 2 && allowFinish" style="margin-right: 10px"
          @click="finishTask()">办理</a-button>
        <a-button v-if="form.status == 2" @click="showWps" style="margin-right: 10px">打印</a-button>
        <a-button @click="cancelClick">取消</a-button>
      </div>
    </div>
    <a-modal title="选择用户" v-model="selectuservisible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
  import assetpurchaseApi from "@/api/asset/purchase";
  import orgApi from "@/api/org";
  import selectuser from "../components/searchuser.vue";
  import dictionaryItemApi from "@/api/system/dictionaryItem";
  import pinyin from "js-pinyin";
  import workflowApi from "@/api/workflow";
  import workflowapi from "@/api/workflow";
  const columns = [
    {
      key: "index",
      align: "center",
      title: "序号",
      width: 70,
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      dataIndex: "name",
      align: "center",
      title: "资产名称",
      scopedSlots: { customRender: "textbox" },
    },
    {
      dataIndex: "type",
      align: "center",
      title: "型号/规格",
      scopedSlots: { customRender: "textbox" },
    },
    {
      dataIndex: "count",
      align: "center",
      title: "数量",
      scopedSlots: { customRender: "count" },
    },
    {
      dataIndex: "unit",
      align: "center",
      title: "单位",
      scopedSlots: { customRender: "textbox" },
    },
    {
      key: "category",
      align: "center",
      title: "资产类别",
      width: 165,
      scopedSlots: { customRender: "category" },
    },
    {
      dataIndex: "remark",
      align: "center",
      title: "备注",
      scopedSlots: { customRender: "textbox" },
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  export default {
    name: "assetpurchase",
    data() {
      return {
        loading: false,
        form: {
          status: 0,
          items: [{}],
        },
        taskId: "",
        allowFinish: false,
        assetcategory: [],
        columns,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        orgs: [],
        category: [],
        way: [],
        selectuservisible: false,
        auditModal: {
          info: null,
          visible: false,
        },
        rules: {
          orgId: [
            {
              required: true,
              message: "请选择部室",
              trigger: "blur",
            },
          ],
          applyTime: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
          applyUserName: [
            {
              required: true,
              message: "请选择申请人",
              trigger: "change",
            },
          ],
        },
      };
    },
    props: {
      info: {
        type: Object,
      },
    },
    mounted() {
      this.getOrgTree();
      this.getDic();
      if (this.info && this.info.id > 0) {
        this.getData();
      }
    },
    components: {
      selectuser,
    },
    methods: {
      getDic() {
        dictionaryItemApi.getItemByDictionaryName("资产类别").then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.assetcategory = res.data;
          }
        });
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      getData() {
        this.loading = true;
        assetpurchaseApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            res.data.applyTime = res.data.applyTime
              ? this.$moment(res.data.applyTime)
              : null;
            delete res.data.orgPath;
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.getTask(res.data.processId);
          }
        });
      },
      async finishTask() {
        if (this.taskId == "" || this.taskId == null) {
          this.$message.error("已办理");
          return;
        }
        this.worktaskloading = true;
        var res = await workflowApi.finishTask(this.taskId);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      getTask(id) {
        this.loading = true;
        this.taskId = "";
        this.allowFinish = false;
        workflowapi.getTask(id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data != null && res.data != "" && res.data.length > 0) {
              this.taskId = res.data[0].id;
              this.allowFinish = true;
              this.$forceUpdate();
            }
          }
        });
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      onSearch() {
        this.selectuservisible = true;
      },
      selectuserCallback(user) {
        this.form.applyUser = user.userName;
        this.form.applyUserName = user.realName;
        this.selectuservisible = false;
      },
      addItem() {
        this.form.items.push({});
      },
      removeItem(index) {
        this.form.items.splice(index, 1);
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgs = res.data.tree;
            if (!this.info) {
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              if (res.data.path) {
                for (var i = 0; i < res.data.path.length; ++i) {
                  var id = res.data.path[i];
                  var find = tree.filter((n) => n.id == id);
                  if (i == res.data.path.length - 1) {
                    this.form.orgName = find[0].name;
                  } else {
                    tree = find[0].children;
                  }
                }
              }

              this.form.applyUserName = res.data.realName;
              this.form.applyUser = localStorage.getItem(
                this.$commons.User.userName
              );
            }
          }
        });
      },
      showWps() {
        let templateId = "assetpurchase";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;
        innerData.notInsertTableRow = "true";
        innerData.createTime =
          innerData.createTime == null
            ? ""
            : this.$moment(innerData.createTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
        if (this.form.items != null && this.form.items.length > 0) {
          let i = 0;
          this.form.items.forEach((a) => {
            a.sort = ++i;
          });
          delete innerData.items;
          innerData.list = this.form.items;
        }
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            let s = a.auditUserSignPicture.indexOf("uas");
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "资产请购",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
      handleSubmit(status) {
        this.$refs.newsCategoryForm.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
            delete formData.createTime;
            delete formData.creator;
            delete formData.creatorName;
            delete formData.auditRecords;
            //添加
            assetpurchaseApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  this.$emit("callback", true);
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
    },
  };
</script>
