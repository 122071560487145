<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body" style="height: 50px">
          <a-button style="float: right" @click="gotoList" type="default">代管项目资金预算列表</a-button>
        </div>
      </div>

      <div :style="{
        padding: '10px',
        width: info == null ? '90%' : '100%',
        margin: '0px auto',
      }">
        <a-form-model ref="escrowFundBudgetform" :model="form" :rules="rules" :label-col="labelCol"
          :wrapper-col="wrapperCol" :class="{ disabled: form.status == 2 }">
          <table style="width: 100%">
            <tr>
              <td colspan="3" style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                ">
                河北省教育科学研究院代管项目资金预算表
              </td>
            </tr>
            <tr>
              <td>
                <a-form-model-item label="编报时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }" prop="reportTime"
                  style="margin-bottom: 0px">
                  <a-date-picker v-model="form.reportTime" />
                </a-form-model-item>
              </td>
              <td style="text-align: left">
                <a-form-model-item label="预算年度" prop="year" :labelCol="{ span: 5 }" :wrapperCol="{ span: 14 }"
                  style="margin-bottom: 0px">
                  <a-input v-model="form.year" />
                </a-form-model-item>
              </td>
              <td>单位金额：元</td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px">
                <table class="formtable">
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      项目名称及编号
                    </td>
                    <td style="text-align: left" colspan="4">
                      <a-form-model-item label="" prop="xiangmu" style="margin-bottom: 0px">
                        <a-input v-model="form.xiangmu" />
                      </a-form-model-item>
                    </td>
                    <td>
                      <a-form-model-item label="类型" prop="type" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14 }"
                        style="margin-bottom: 0px">
                        <a-select v-model="form.type" placeholder="请选择">
                          <a-select-option value="教研">教研</a-select-option>
                          <a-select-option value="科研">科研</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      项目资金总额
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="amount" style="margin-bottom: 0px">
                        <a-input-number v-model="form.amount" :min="0" :precision="2" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      立项单位
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="projectApproval" style="margin-bottom: 0px">
                        <a-input v-model="form.projectApproval" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      项目资金来源
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="priceSource" style="margin-bottom: 0px">
                        <a-input v-model="form.priceSource" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      起止年限
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="time" style="margin-bottom: 0px">
                        <a-range-picker @change="timeChanged" v-model="form.time" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      项目负责人
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="responsiblePersonName" style="margin-bottom: 0px">
                        <a-input-search class="select_search" readOnly placeholder="请选择"
                          v-model="form.responsiblePersonName" @click="onSearch" @search="onSearch">
                          <a-button type="primary" slot="enterButton" icon="plus" title="添加" />
                        </a-input-search>
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      项目成员
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="partPerson" style="margin-bottom: 0px">
                        <a-input v-model="form.partPerson" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      年度预算金额
                    </td>
                    <td style="text-align: left; width: 150px">
                      <a-form-model-item label="" prop="yearsPrice" style="margin-bottom: 0px">
                        <a-input-number v-model="form.yearsPrice" :min="0" :precision="2" @blur="yearsPriceBlur"
                          style="width: 100%" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      已支付金额
                    </td>
                    <td style="text-align: left">
                      <a-form-model-item label="" prop="payPrice" style="margin-bottom: 0px">
                        <a-input-number v-model="form.payPrice" :min="0" :precision="2" @blur="payPriceBlur"
                          style="width: 100%" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      支付进度比例（%）
                    </td>
                    <td style="text-align: left; width: 150px">
                      <a-form-model-item label="" prop="payProgressRatio" style="margin-bottom: 0px">
                        <a-input-number v-model="form.payProgressRatio" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      待支付金额
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="notPayPrice" style="margin-bottom: 0px">
                        <a-input-number v-model="form.notPayPrice" :precision="2" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 150px">
                      <span style="color: red">*</span>
                      本次申请经费金额
                    </td>
                    <td style="text-align: left" colspan="2">
                      <a-form-model-item label="" prop="fundPrice" style="margin-bottom: 0px">
                        <a-input-number v-model="form.fundPrice" :precision="2" style="width: 100%" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>项目预算明细</td>
                    <td colspan="5" style="padding: 0px">
                      <a-row>
                        <a-col :span="8" style="
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          ">列支科目</a-col>
                        <a-col :span="4" style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          ">金额</a-col>
                        <a-col :span="12" style="
                            border-left: solid 1px #cccccc;
                            border-bottom: solid 1px #cccccc;
                            height: 50px;
                            line-height: 50px;
                          ">用途（与项目的关系）</a-col>
                      </a-row>
                      <a-row v-for="(item, index) in form.items" :key="index">
                        <a-col :span="8" :style="{
                          borderLeft: 'solid 1px #cccccc',
                          borderBottom: 'solid 1px #cccccc',
                          height: '50px',
                          padding: '5px',
                        }">
                          <a-select v-model="item.xiangmu" allowClear>
                            <a-select-opt-group v-for="group in xiangmuDataSource" :key="group.id">
                              <span slot="label" style="color: #0000cc; font-size: 16px">
                                {{ group.itemValue }}
                              </span>
                              <a-select-option v-for="opt in group.children" :key="opt.id" :value="opt.itemValue">{{
                                opt.itemValue }}</a-select-option>
                            </a-select-opt-group>
                          </a-select>
                        </a-col>
                        <a-col :span="4" :style="{
                          borderLeft: 'solid 1px #cccccc',
                          borderBottom: 'solid 1px #cccccc',
                          height: '50px',
                          padding: '5px',
                        }">
                          <a-input-number v-model="item.amount" :min="0" :precision="2" @change="itemAmountChange"
                            @blur="itemAmountBlur" style="width: 100%;" />
                        </a-col>
                        <a-col :span="12" :style="{
                          borderLeft: 'solid 1px #cccccc',
                          borderBottom: 'solid 1px #cccccc',
                          height: '50px',
                          padding: '5px',
                        }">
                          <a-input v-model="item.remark" />
                        </a-col>
                      </a-row>
                    </td>
                  </tr>
                  <tr>
                    <td>附件</td>
                    <td colspan="5" style="text-align: left">
                      <a-form-model-item label="" style="margin-bottom: 0px">
                        <a-upload :action="uploadFileUrl" :fileList="fileList" @change="handleFileChange"
                          @preview="showWpsRead" :remove="upLoadRemove" style="pointer-events: all;">
                          <div v-if="form.status == 0 || form.status == 3">
                            <a-button><a-icon type="upload" />上传文件</a-button>
                          </div>
                        </a-upload>
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>编报人（经办人）</td>
                    <td style="text-align: right">
                      <div v-if="form.status == 0 || form.status == 3">
                        {{ form.postUserName }}
                      </div>
                      <div style="text-align: right; display: inline-block" v-else>
                        <img style="width: 120px" :src="form.postUserPic" v-if="form.postUserPic" />
                        <span v-else>{{ form.postUserName }} </span>
                        <div>
                          {{ form.reportTime.format("YYYY年MM月DD日") }}
                        </div>
                      </div>
                    </td>
                    <td style="width: 150px">项目负责人意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="XiangMuFuZheRen" @callback="auditCb" />
                    </td>
                    <td style="width: 150px">项目主管部门意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="BuShiFuZeRen" @callback="auditCb" />
                    </td>
                  </tr>
                  <tr>
                    <td>财务意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="CaiWu" @callback="auditCb" />
                    </td>
                    <td>办公室财务副主任意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="BanGongShiFuZhuRen" @callback="auditCb" />
                    </td>
                    <td>办公室主任意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="BanGongShiZhuRen" @callback="auditCb" />
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">主管项目院领导意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="FenGuanLingDao" @callback="auditCb" />
                    </td>
                    <td style="width: 150px">主管财务院领导意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="BanGongShiFenGuanLingDao" @callback="auditCb" />
                    </td>
                    <td>院长意见</td>
                    <td>
                      <auditPrint :infoData="form" taskPrintKey="YuanChang" @callback="auditCb" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </a-form-model>
        <a-row style="text-align: center; margin-top: 20px">
          <a-col>
            <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 10px"
              @click="handleSubmit(0)">保存</a-button>
            <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
              @confirm="handleSubmit(1)">
              <a-button type="danger" style="margin-right: 10px">提交</a-button>
            </a-popconfirm>
            <a-button type="primary" v-if="form.processExecuteStatus" style="margin-right: 10px"
              @click="showaudit()">审批</a-button>
            <a-button v-if="form.status == 2" @click="showWps" style="margin-right: 10px">打印</a-button>
            <a-button type="default" v-if="modal" @click="cancelClick">取消</a-button>
          </a-col>
        </a-row>
        <a-modal title="选择用户" v-model="selectuseModal.visible" width="70%" :footer="false" destroyOnClose
          :maskClosable="false">
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose
          :maskClosable="false">
          <audit :info="auditModal.info" :formValidPass="formValidPass" @callback="auditCallBack" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import escrowfundbudgetApi from "@/api/work/escrowfundbudget";
  import orgApi from "@/api/org";
  import selectuser from "../components/searchuser.vue";
  import pinyin from "js-pinyin";
  import dictionaryItemApi from "@/api/system/dictionaryItem";
  import request from "@/request";
  import { min } from "moment";
  export default {
    name: "escrowfundbudget",
    data() {
      return {
        form: {
          reportTime: null,
          postUser: "",
          postUserName: "",
          type: "",
          status: 0,
          price: "元",
          items: [{}, {}, {}, {}, {}, {}, {}],
          yearsPrice: 0,
          payPrice: 0,
          notPayPrice: 0,
          fundPrice: 0,
        },
        rules: {
          reportTime: [
            {
              required: true,
              message: "请选择编报时间",
              trigger: "change",
            },
          ],
          year: [
            {
              required: true,
              message: "请输入预算年度",
              trigger: "blur",
            },
          ],
          xiangmu: [
            {
              required: true,
              message: "请输入项目名称及编号",
              trigger: "blur",
            },
          ],
          type: [
            {
              required: true,
              message: "请选择类型",
              trigger: "change",
            },
          ],
          amount: [
            {
              required: true,
              message: "请输入项目总金额",
              trigger: "blur",
            },
          ],
          projectApproval: [
            {
              required: true,
              message: "请输入项目立项单位",
              trigger: "blur",
            },
          ],
          priceSource: [
            {
              required: true,
              message: "请输入项目资金来源",
              trigger: "blur",
            },
          ],
          time: [
            {
              required: true,
              message: "请选择起止年限",
              trigger: "change",
            },
          ],
          responsiblePerson: [
            {
              required: true,
              message: "请选择负责人",
              trigger: "blur",
            },
          ],
          partPerson: [
            {
              required: true,
              message: "请输入项目成员",
              trigger: "blur",
            },
          ],
          yearsPrice: [
            {
              required: true,
              message: "请输入年度预算金额",
              trigger: "blur",
            },
          ],
          payPrice: [
            {
              required: true,
              message: "请输入已支付金额",
              trigger: "blur",
            },
          ],
        },
        xiangmuDataSource: [],
        uploadFileUrl: "",
        fileList: [],
        labelCol: { span: 6 },
        wrapperCol: { span: 23 },
        orgs: [],
        orgDates: [],
        loading: false,
        selectuseModal: {
          visible: false,
          record: null,
          type: 0,
        },
        auditModal: {
          info: null,
          visible: false,
        },
        formValidPass: true,
      };
    },
    props: {
      info: {
        type: Object,
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    components: { selectuser },
    mounted() {
      this.getOrgTree();
      this.getXiangmuDataSource();
      this.uploadFileUrl = request.getUploadUrl("document");

      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        this.form.reportTime = this.$moment();
      }
    },
    methods: {
      auditCb(data) {
        this.$emit("callback", data != null);
        this.getData();
      },
      yearsPriceBlur(e) {
        let value = e.target.value;
        this.form.notPayPrice = value - this.form.payPrice;
        if (this.form.payPrice > 0) {
          this.form.payProgressRatio = (this.form.payPrice / value) * 100;
        }
      },
      payPriceBlur(e) {
        let value = e.target.value;
        this.form.notPayPrice = this.form.yearsPrice - value;
        if (this.form.yearsPrice > 0) {
          this.form.payProgressRatio = (value / this.form.yearsPrice) * 100;
        }
      },
      itemAmountBlur(e) {
        this.form.fundPrice = 0;
        for (let i = 0; i < this.form.items.length; i++) {
          if (!this.form.items[i].amount) {
            this.form.fundPrice = this.form.fundPrice + 0;
          } else {
            this.form.fundPrice = this.form.fundPrice + this.form.items[i].amount;
          }
        }
      },
      upLoadRemove() {
        return this.form.status == 0 || this.form.status == 3;
      },
      handleFileChange(fileInfo) {
        this.fileList = fileInfo.fileList;
        if (fileInfo.file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      gotoList() {
        this.$router.push({ path: "/business/escrowfundbudgetlist" });
      },
      async showaudit() {
        let validRes = await this.$refs.escrowFundBudgetform.validate();
        if (!validRes) {
          this.$message.error("请填写必填项");
          return;
        }
        this.formValidPass = validRes;
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      async auditCallBack(data) {
        let close = true;
        this.auditModal.visible = false;
        if (data != null && data.updateModel) {
          close = false;
          let validRes = await this.$refs.escrowFundBudgetform.validate();
          if (!validRes) {
            return false;
          }
          this.loading = true;
          this.loadingTip = "保存中...";
          let formData = {
            id: this.info.id,
            xiangmu: this.form.xiangmu,
            amount: this.form.amount,
            payPrice: this.form.payPrice,
            payProgressRatio: this.form.payProgressRatio,
            notPayPrice: this.form.notPayPrice,
            fundPrice: this.form.fundPrice,
            price: this.form.price,
            priceSource: this.form.priceSource,
            projectApproval: this.form.projectApproval,
            yearsPrice: this.form.yearsPrice,
            items: JSON.stringify(this.form.items),
            updateOnly: true,
          };
          let res = await escrowfundbudgetApi.addOrUpdate(formData);
          if (res.errorCode == "0000") {
            this.loading = false;
            close = true;
          }
        }
        if (close) {
          this.$emit("callback", data != null);
        }
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      onSearch(record, type) {
        this.selectuseModal.visible = true;
        this.selectuseModal.record = record;
        this.selectuseModal.type = type;
      },
      selectuserCallback(user) {
        this.form.responsiblePerson = user.userName;
        this.form.responsiblePersonName = user.realName;
        this.selectuseModal.visible = false;
      },
      itemAmountChange(v) {
        let amount = 0;
        this.form.items.forEach((a) => {
          if (!isNaN(parseFloat(a.amount)))
            amount = amount + parseFloat(a.amount);
        });
        // this.form.yearsPrice = amount;
      },
      getXiangmuDataSource() {
        dictionaryItemApi.getItemByDictionaryName("列支科目").then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            let data = res.data;
            let groups = data.filter((a) => a.parentId == 0);
            groups.forEach((e, i) => {
              e.children = data.filter((a) => a.parentId == e.id);
            });
            this.xiangmuDataSource = groups;
            this.xiangmuDataSourceBack = groups;
          }
        });
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgDates = res.data.tree[0].children;
            res.data.tree[0].orgsNumber = this.orgsNumber;
            this.orgsNumber = this.orgsNumber++;
            this.orgs = res.data.tree;
            if (!this.data) {
              // this.form.type = res.data.path;
              // this.form.orgId=res.data.path;
              let tree = this.orgs;
              for (var i = 0; i < res.data.path.length; ++i) {
                var id = res.data.path[i];
                var find = tree.filter((n) => n.id == id);
                if (i == res.data.path.length - 1) {
                  // this.form.type = find[0].name;
                  this.form.orgId = find[0].id;
                  this.form.orgName = find[0].name;
                } else {
                  tree = find[0].children;
                }
              }
            }
            this.form.postUserName = res.data.realName;
            this.form.postUser = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        });
      },
      getData() {
        this.loading = true;
        escrowfundbudgetApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            delete res.data.orgPath;
            res.data.reportTime = this.$moment(res.data.reportTime, "YYYY-MM-DD");
            res.data.startTime = this.$moment(res.data.startTime, "YYYY-MM-DD");
            res.data.endTime = this.$moment(res.data.endTime, "YYYY-MM-DD");
            res.data.items = JSON.parse(res.data.items);
            this.form = res.data;
            this.form.time = [
              this.$moment(res.data.startTime, "YYYY-MM-DD"),
              this.$moment(res.data.endTime, "YYYY-MM-DD"),
            ];
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            if (res.data.files) {
              var d = JSON.parse(res.data.files);
              var files = [];
              for (var i = 1; i <= d.length; ++i) {
                var a = d[i - 1];
                files.push({
                  uid: i,
                  name: a.title,
                  status: "done",
                  response: a,
                  url: a.url,
                });
              }
              this.fileList = files;
            }
          }
        });
      },
      timeChanged(times) {
        this.form.startTime = times[0];
        this.form.endTime = times[1];
      },
      handleSubmit(status) {
        if (
          this.form.responsiblePerson == null ||
          this.form.responsiblePerson == ""
        ) {
          this.$message.error("请选择项目负责人");
          return;
        }
        let extensionSettingData = {};
        extensionSettingData["node_代管资金预算-项目负责人意见"] = {
          targetUsers: this.form.responsiblePerson,
          count: 1,
        };

        if (this.form.items.length == 0) {
          this.$message.error("请添加项目明细");
          return;
        }
        this.form.extensionSetting = JSON.stringify(extensionSettingData);
        this.$refs.escrowFundBudgetform.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            // formData.orgId=formData.orgId[formData.orgId.length-1];
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.createTime;
            delete formData.creator;
            delete formData.creatorName;
            delete formData.auditRecords;
            formData.reportTime = formData.reportTime.format("YYYY-MM-DD");
            formData.startTime = formData.startTime.format("YYYY-MM-DD");
            formData.endTime = formData.endTime.format("YYYY-MM-DD");
            if (this.fileList.length > 0) {
              var file = [];
              this.fileList.forEach((f) => {
                file.push({
                  title: f.response.title,
                  url: f.response.url,
                });
              });
              formData.files = JSON.stringify(file);
            }
            formData.items = JSON.stringify(formData.items);
            //添加
            escrowfundbudgetApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  if (this.modal) {
                    this.$emit("callback", true);
                  } else {
                    this.gotoList();
                  }
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      showWpsRead(file) {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          type.toLowerCase().indexOf("doc") <= -1 &&
          type.toLowerCase().indexOf("xls") <= -1 &&
          type.toLowerCase().indexOf("ppt") <= -1
        ) {
          window.open(file.response.url, "_blank");
        } else {
          let fileId =
            "file" +
            file.response.url
              .substring(
                file.response.url.lastIndexOf("/") + 1,
                file.response.url.lastIndexOf(".")
              )
              .replaceAll("-", "");
          let url = file.response.url.substring(
            file.response.url.indexOf("oabucket")
          );
          let type = file.response.url.substring(
            file.response.url.lastIndexOf(".") + 1
          );
          localStorage.removeItem("fileRrl");
          localStorage.setItem("fileRrl", url);
          localStorage.removeItem("fileName");
          localStorage.setItem("fileName", file.name);
          localStorage.removeItem("fileId");
          localStorage.setItem("fileId", fileId);
          localStorage.removeItem("fileTpye");
          localStorage.setItem("fileTpye", type);
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              //要传的参数
              fileName: file.name,
            },
          });

          window.open(jump.href, "_blank");
        }
      },
      showWps() {
        let templateId = "escrowfundbudget";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;
        innerData.amount = innerData.amount == null ? "" : innerData.amount.toFixed(2);
        innerData.yearsPrice = innerData.yearsPrice == null ? "" : innerData.yearsPrice.toFixed(2);
        innerData.payPrice = innerData.payPrice == null ? "" : innerData.payPrice.toFixed(2);
        innerData.notPayPrice = innerData.notPayPrice == null ? "" : innerData.notPayPrice.toFixed(2);
        innerData.fundPrice = innerData.fundPrice == null ? "" : innerData.fundPrice.toFixed(2);
        innerData.reportTime =
          innerData.reportTime == null
            ? ""
            : this.$moment(innerData.reportTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
        innerData.time =
          innerData.startTime.format("YYYY年MM月DD日") +
          "至 " +
          innerData.endTime.format("YYYY年MM月DD日");
        if (innerData.postUserPic != null && innerData.postUserPic != "") {
          innerData.postUserPic =
            "http://localhost:9000/" +
            innerData.postUserPic.substring(innerData.postUserPic.indexOf("uas"));
        }
        if (this.form.items != null && this.form.items.length > 0) {
          let i = 0;
          this.form.items.forEach((a) => {
            a.amount = a.amount == null ? "" : a.amount.toFixed(2);
            a.remark = a.remark == null ? "" : a.remark;
            a.xiangmu = a.xiangmu == null ? "" : a.xiangmu;
            a.sort = ++i;
          });
          delete innerData.items;
          innerData.list = this.form.items;
        }
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "代管经费支出预算",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
