<style>
  .ant-col-6 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: auto;
  }
</style>

<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="info == null">
        <div class="search-body" style="height: 50px">
          <a-button style="float: right" @click="gotoList" type="default">公务租车申请列表</a-button>
        </div>
      </div>

      <div style="padding: 10px; width: 900px; margin: auto">
        <a-form-model ref="loanbillform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
          :class="{ disabled: form.status == 2 }">
          <table style="width: 100%">
            <tr>
              <td colspan="3" style="
                  font-size: 40px;
                  text-align: center;
                  padding-bottom: 20px;
                ">
                公务租车申请审批单
              </td>
            </tr>
            <tr>
              <td style="width: 400px">
                <a-form-model-item label="申请部室" :wrapperCol="{ span: 18 }" prop="org"
                  style="margin-bottom: 0px; width: 100%">
                  <a-cascader :options="orgs" v-model="form.orgId" @change="orgChanged" :fieldNames="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }" placeholder="请选择部室" />
                </a-form-model-item>
              </td>

              <td>
                <a-form-model-item label="经办人" :wrapperCol="{ span: 10 }" prop="userId" style="margin-bottom: 0px">
                  <a-input v-model="form.userName" disabled />
                </a-form-model-item>
              </td>
              <td>
                <a-form-model-item label="申请时间" :labelCol="{ span: 10 }" :wrapperCol="{ span: 14 }" prop="createTime"
                  style="margin-bottom: 0px">
                  <a-date-picker v-model="form.createTime" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 10px">
                <table class="formtable">
                  <tr>
                    <td style="width: 150px">
                      <span style="color: red; font-size: 14px">*</span>
                      用车事由
                    </td>
                    <td style="text-align: left" colspan="5">
                      <a-form-model-item :wrapperCol="{ span: 24 }" prop="content" style="margin-bottom: 0px">
                        <a-input v-model="form.content" style="width: 100%" v-bind:readOnly="form.status != 0" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 100px">
                      <span style="color: red; font-size: 14px">*</span>
                      预计出行人员
                    </td>
                    <td style="text-align: left" colspan="5">
                      <a-form-model-item :wrapperCol="{ span: 24 }" prop="peoples" style="margin-bottom: 0px">
                        <MultiSearchUser :checkUsers="selUser" @callback="selectUsersCallback" />
                      </a-form-model-item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span style="color: red; font-size: 14px">*</span>
                      预计用车时间
                    </td>
                    <td style="text-align: left" colspan="5">
                      <a-form-model-item :wrapperCol="{ span: 24 }" prop="time" style="margin-bottom: 0px">
                        <a-range-picker v-model="form.time" @change="onDateChange" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color: red; font-size: 14px">*</span>
                      用车类型
                    </td>
                    <td colspan="4" style="text-align: left">
                      <div>
                        <a-select v-model="form.carType" placeholder="请选择用车类型" style="width: 300px">
                          <a-select-option :value="item.itemName" v-for="item in carTypeList" :key="item.itemValue">{{
                            item.itemName }}</a-select-option>
                        </a-select>
                        <span style="color: red; font-size: 14px; margin-left: 30px">*</span>
                        用车数量
                        <a-input-number v-model="form.carCount" style="width: 100px"
                          v-bind:readOnly="form.status != 0" />
                      </div>
                      <div style="margin-top: 10px">
                        <a-select v-model="form.carType2" placeholder="请选择用车类型" style="width: 300px">
                          <a-select-option :value="item.itemName" v-for="item in carTypeList" :key="item.itemValue">{{
                            item.itemName }}</a-select-option>
                        </a-select>
                        <span style="color: red; font-size: 14px; margin-left: 30px">&nbsp;</span>
                        用车数量
                        <a-input-number v-model="form.carCount2" style="width: 100px"
                          v-bind:readOnly="form.status != 0" />
                      </div>

                      <div style="margin-top: 10px">
                        <a-select v-model="form.carType3" placeholder="请选择用车类型" style="width: 300px">
                          <a-select-option :value="item.itemName" v-for="item in carTypeList" :key="item.itemValue">{{
                            item.itemName }}</a-select-option>
                        </a-select>
                        <span style="color: red; font-size: 14px; margin-left: 30px">&nbsp;</span>
                        用车数量
                        <a-input-number v-model="form.carCount3" style="width: 100px"
                          v-bind:readOnly="form.status != 0" />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span style="color: red; font-size: 14px">*</span> 出发地
                    </td>
                    <td colspan="2">
                      <a-form-model-item :wrapperCol="{ span: 24 }" prop="startAdd" style="margin-bottom: 0px"
                        v-bind:readOnly="form.status != 0">
                        <a-input v-model="form.startAdd" style="width: 100%" v-bind:readOnly="form.status != 0" />
                      </a-form-model-item>
                    </td>
                    <td style="width: 200px">
                      <span style="color: red; font-size: 14px">*</span> 目的地
                    </td>
                    <td colspan="2">
                      <a-form-model-item :wrapperCol="{ span: 24 }" prop="endAdd" style="margin-bottom: 0px">
                        <a-input v-model="form.endAdd" style="width: 100%" v-bind:readOnly="form.status != 0" />
                      </a-form-model-item>
                    </td>
                  </tr>
                  <tr>
                    <td>办公室主任<br />意见</td>
                    <td colspan="2">
                      <auditshow :infoData="form" taskKey="公务租车申请-办公室主任意见" @callback="auditCb" />
                    </td>
                    <td>主管办公室的院领导<br />意见</td>
                    <td colspan="2">
                      <auditshow :infoData="form" taskKey="公务租车申请-主管办公室的院领导意见" @callback="auditCb" />
                    </td>
                  </tr>
                  <tr>
                    <td>院长意见</td>
                    <td style="text-align: left" colspan="5">
                      <auditshow :infoData="form" taskKey="公务租车申请-院长意见" @callback="auditCb" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </a-form-model>
        <a-row style="text-align: center; margin-top: 10px">
          <a-col>
            <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 5px"
              @click="handleSubmit(0)">保存</a-button>
            <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
              @confirm="handleSubmit(1)">
              <a-button style="margin-right: 5px" type="danger">提交</a-button>
            </a-popconfirm>
            <a-button type="primary" v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
              style="margin-right: 5px" @click="showaudit()">审批</a-button>
            <a-button type="default" style="margin-right: 5px" v-if="form.status == 2" @click="showWps"
              class="cancelBtn">打印</a-button>
            <a-button type="default" @click="cancelClick" class="cancelBtn">取消
            </a-button>
          </a-col>
        </a-row>
        <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose
          :maskClosable="false">
          <audit :info="auditModal.info" @callback="auditCallBack" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import specialfundApi from "@/api/work/bookcar";
  import orgApi from "@/api/org";
  import MultiSearchUser from "../components/MultiSearchUser.vue";
  import pinyin from "js-pinyin";
  import dictionaryItemApi from "@/api/system/dictionaryItem";
  const columns = [
    {
      align: "center",
      dataIndex: "xiangmu",
      title: "列支项目",
      scopedSlots: { customRender: "textbox" },
    },
    {
      align: "center",
      dataIndex: "amount",
      title: "金额",
      scopedSlots: { customRender: "number" },
    },
    {
      align: "center",
      dataIndex: "remark",
      title: "使用资金的理由及与项目的关系",
      scopedSlots: { customRender: "textbox" },
    },
  ];
  export default {
    name: "specialfund",
    data() {
      return {
        form: {
          status: 0,
          createTime: null,
          content: "",
          peoples: "",
          useBeginTime: "",
          useEndTime: "",
          carType: "",
          carType2: "",
          carType3: "",
          startAdd: "",
          endAdd: "",
          carCount: 1,
          carCount2: 0,
          carCount3: 0,
        },
        selUser: [],
        columns,
        labelCol: { span: 6 },
        wrapperCol: { span: 23 },
        orgs: [],
        pepoles: {},
        orgDates: [],
        loading: false,
        selectuseModal: {
          visible: false,
        },
        auditModal: {
          info: null,
          visible: false,
        },
        rules: {
          org: [
            {
              required: true,
              message: "请选择部室",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.orgId.length == 0) {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          createTime: [
            {
              required: true,
              message: "请选择编报时间",
              trigger: "change",
            },
          ],
          content: [
            {
              required: true,
              message: "请输入租车理由",
              trigger: "blur",
            },
          ],
          useBeginTime: [
            {
              required: true,
              message: "请选择用车时间",
              trigger: "change",
            },
          ],
          carCount: [
            {
              required: true,
              message: "请输入用车数量",
              trigger: "blur",
            },
          ],
          startAdd: [
            {
              required: true,
              message: "请输入出发地",
              trigger: "blur",
            },
          ],
          endAdd: [
            {
              required: true,
              message: "请输入目的地",
              trigger: "blur",
            },
          ],
          peoples: [
            {
              required: true,
              message: "请输入出差人员",
              trigger: "blur",
            },
          ],
          time: [
            {
              required: true,
              message: "请选择用车时间",
              trigger: "blur",
            },
          ],
        },
        carTypeList: [],
      };
    },
    props: {
      info: {
        type: Object,
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    components: { MultiSearchUser },
    created() {
      this.getDic();
    },
    mounted() {
      this.getOrgTree();
      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        this.form.createTime = this.$moment();
      }
    },
    methods: {
      getDic() {
        dictionaryItemApi.getItemByDictionaryName("公务车型").then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.carTypeList = res.data;
          }
        });
      },
      auditCb(data) {
        this.$emit("callback", data != null);
        this.getData();
      },
      onDateChange(date, dateString) {
        this.form.useBeginTime = dateString[0];
        this.form.useEndTime = dateString[1];
      },
      pepolesselected() {
        _this.$forceUpdate();
      },
      gotoList() {
        this.$router.push({ path: "/business/bookcarlist" });
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      onSearch() {
        this.selectuseModal.multi = true;
        this.selectuseModal.visible = true;
      },
      selectUsersCallback(users) {
        this.selUser = [...users];
        this.form.peoples = users.map((u) => u.realName).join(",");
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgDates = res.data.tree[0].children;
            res.data.tree[0].orgsNumber = this.orgsNumber;
            this.orgsNumber = this.orgsNumber++;
            this.orgs = res.data.tree;
            if (!this.data) {
              this.form.type = res.data.path;
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              for (var i = 0; i < res.data.path.length; ++i) {
                var id = res.data.path[i];
                var find = tree.filter((n) => n.id == id);
                if (i == res.data.path.length - 1) {
                  this.form.type = find[0].name;
                  this.form.orgName = find[0].name;
                } else {
                  tree = find[0].children;
                }
              }
            }
            this.form.userName = res.data.realName;
            this.form.postUser = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        });
      },
      getData() {
        this.loading = true;
        specialfundApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.createTime = this.$moment(res.data.createTime, "YYYY-MM-DD");
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.form.time = [this.form.useBeginTime, this.form.useEndTime];
            if (res.data.userInfo) {
              this.selUser = JSON.parse(res.data.userInfo);
            }
            let arr = [1];
            arr.push(res.data.orgId);
            this.form.orgId = arr;
          }
        });
      },
      handleSubmit(status) {
        this.$refs.loanbillform.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.creator;
            delete formData.creatorName;
            delete formData.auditRecords;
            formData.createTime = formData.createTime.format("YYYY-MM-DD");
            formData.peoplesCount = formData.peoples.split(",").length;
            formData.userInfo = JSON.stringify(this.selUser);

            this.loading = true;
            //添加
            specialfundApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  if (this.modal) {
                    this.$emit("callback", true);
                  } else {
                    this.gotoList();
                  }
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      showWps() {
        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;

        innerData.reportTime =
          innerData.createTime == null
            ? ""
            : this.$moment(innerData.createTime, "YYYY-MM-DD").format(
              "YYYY 年 MM 月 DD 日"
            );
        innerData.useBeginTime = this.$moment(
          innerData.time[0],
          "YYYY-MM-DD"
        ).format("MM 月 DD 日");
        innerData.useEndTime = this.$moment(
          innerData.time[1],
          "YYYY-MM-DD"
        ).format("MM 月 DD 日");
        innerData.dayCount =
          Math.ceil(
            Math.abs(new Date(innerData.time[1]) - new Date(innerData.time[0])) /
            (1000 * 60 * 60 * 24)
          ) + 1;
        if (this.form.items != null && this.form.items.length > 0) {
          let i = 0;
          this.form.items.forEach((a) => {
            a.amount = a.amount == null ? "" : a.amount;
            a.remark = a.remark == null ? "" : a.remark;
            a.xiangmu = a.xiangmu == null ? "" : a.xiangmu;
            a.sort = ++i;
          });
          delete innerData.items;
          innerData.list = this.form.items;
        }
        innerData.carTypeDsc =
          innerData.carType + " " + innerData.carCount + " 辆";
        if (innerData.carType2 != null && innerData.carType2 != "") {
          innerData.carTypeDsc +=
            "；" + innerData.carType2 + " " + innerData.carCount2 + " 辆";
        }
        if (innerData.carType3 != null && innerData.carType3 != "") {
          innerData.carTypeDsc +=
            "；" + innerData.carType3 + " " + innerData.carCount3 + " 辆";
        }
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            let s = a.auditUserSignPicture.indexOf("uas");
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: "bookcar_print_template",
            fileType: "public",
            openType: "create",
            fileName: "bookcar",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
<style scoped></style>
