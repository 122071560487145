<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="info == null">
        <div class="search-body" style="height: 50px">
        </div>
      </div>

      <div :style="{
        padding: '10px',
        width: info == null ? '90%' : '100%',
        margin: '0px auto',
      }">
        <a-form-model ref="loanbillform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
          :class="{ disabled: form.status == 2 }">
          <a-row>
            <p style="font-size: 40px; text-align: center; padding-bottom: 20px">
              收入票据整理单
            </p>
            <a-col :span="7">
              <a-form-model-item label="单位" prop="company" style="margin-bottom: 0px">
                <a-input placeholder="请输入单位" v-model="form.company" readOnly></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item label="日期" prop="applyTime">
                <a-date-picker v-model="form.applyTime" />
              </a-form-model-item>
            </a-col>
            <a-col :span="7">
              <a-form-model-item label="编号" prop="billNum" style="margin-bottom: 0px">
                <a-input v-model="form.billNum" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <table style="width: 100%" class="formtable">
            <tr>
              <td style="text-align: left" colspan="5" rowspan="3">
                <a-form-model-item :label-col="{ span: 3 }" label="内容：" style="margin-bottom: 0px" prop="content">
                  <a-input v-model="form.content" />
                </a-form-model-item>
                <a-form-model-item :label-col="{ span: 3 }" label="金额(大写)：" prop="price" style="margin-bottom: 0px">
                  <a-input-number placeholder="请输入金额" v-model="form.price"
                    style="width: 20%; float: right; margin-top: 3px" @change="changePrice" :precision="2" />
                  <a-input readOnly v-model="form.priceCn" style="width: 80%" />
                </a-form-model-item>
              </td>
              <td style="width: 200px">付款方式</td>
              <td style="width: 200px">出纳签字</td>
            </tr>
            <tr>
              <td style="height: 50px"></td>
              <td style="height: 50px"></td>
            </tr>
            <tr>
              <td style="height: 50px"></td>
              <td style="height: 50px"></td>
            </tr>
            <tr>
              <td style="width: 100px" rowspan="3">单位审批</td>
              <td style="text-align: left">
                财务：
                <auditPrint :infoData="form" taskPrintKey="CaiWu" @callback="auditCb" />
              </td>
              <td style="text-align: left">
                办公室财务副主任：
                <auditPrint :infoData="form" taskPrintKey="BanGongShiFuZhuRen" @callback="auditCb" />
              </td>
              <td style="width: 110px" rowspan="3">核算中心</td>
              <td style="text-align: left" rowspan="3">
                票据审核：<br /><br />
                会计主管: <br /><br />
                核算中心领导: <br /><br />
              </td>
              <td style="height: 50px"></td>
              <td style="height: 50px"></td>
            </tr>
            <tr>
              <td style="text-align: left">
                办公室主任:
                <auditPrint :infoData="form" taskPrintKey="BanGongShiZhuRen" @callback="auditCb" />
              </td>
              <td style="text-align: left">
                分管财务院领导:
                <auditPrint :infoData="form" taskPrintKey="FenGuanLingDao" @callback="auditCb" />
              </td>
              <td style="height: 50px"></td>
            </tr>
            <tr>
              <td style="text-align: left" colspan="2">
                院长:
                <auditPrint :infoData="form" taskPrintKey="YuanChang" @callback="auditCb" />
              </td>
              <td style="text-align: left" colspan="2">
                业务经办人：
                <a-input v-if="form.status == 0 || form.status == 3" v-model="form.creatorName" style="width: 76%"
                  readOnly />
                <div style="text-align: right; display: inline-block" v-else>
                  <img style="width: 120px" :src="form.creatorPic" v-if="form.creatorPic" />
                  <span v-else>{{ form.creatorName }} </span>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 200px">附件</td>
              <td colspan="6">
                <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 6 }" label="附件张数："
                  style="margin-bottom: 0px" prop="attachNum">
                  <a-input v-model="form.attachNum" style="width: 160px" />
                  <a-upload accept="image/jpeg,image/png,application/pdf" :action="uploadFileUrl" :fileList="fileList"
                    @change="handleFileChange" :remove="upLoadRemove" style="pointer-events: all;">
                    <div v-if="form.status == 0 || form.status == 3">
                      <a-button><a-icon type="upload" />上传文件</a-button>
                    </div>
                  </a-upload>
                </a-form-model-item>
              </td>
            </tr>
          </table>
        </a-form-model>
        <a-row style="text-align: center; margin-top: 20px">
          <a-col>
            <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 5px"
              @click="handleSubmit(0)">保存</a-button>
            <a-popconfirm style="margin-right: 5px" v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？"
              @confirm="handleSubmit(1)">
              <a-button type="danger">提交</a-button>
            </a-popconfirm>
            <a-button type="primary" v-if="form.processExecuteStatus" style="margin-right: 5px"
              @click="showaudit()">审批</a-button>
            <a-button v-if="form.status == 2" @click="showWps" style="margin-right: 5px">打印</a-button>
            <a-button type="default" v-if="modal" @click="cancelClick">取消</a-button>
          </a-col>
        </a-row>
        <a-modal title="选择用户" v-model="selectuseModal.visible" width="70%" :footer="false" destroyOnClose
          :maskClosable="false">
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose
          :maskClosable="false">
          <audit :info="auditModal.info" :formValidPass="formValidPass" @callback="auditCallBack" />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import incomebillApi from "@/api/work/incomebill.js";
  import orgApi from "@/api/org";
  import selectuser from "../components/searchuser.vue";
  import pinyin from "js-pinyin";
  import request from "@/request";
  export default {
    name: "incomebill",
    data() {
      return {
        form: {
          company: "河北省教育科学研究院",
          applyTime: null,
          status: 0,
          price: "",
          priceCn: "",
          creatorName: "",
        },
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        uploadFileUrl: "",
        orgs: [],
        orgDates: [],
        loading: false,
        fileList: [],
        selectuseModal: {
          visible: false,
          record: null,
          type: 0,
        },
        auditModal: {
          info: null,
          visible: false,
        },
        rules: {
          org: [
            {
              required: true,
              message: "请选择部室",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.orgId.length == 0) {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          applyTime: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
          billNum: [
            {
              required: false,
              message: "请输入编号",
              trigger: "blur",
            },
          ],
          content: [
            {
              required: true,
              message: "请输入内容",
              trigger: "blur",
            },
          ],
          price: [
            {
              required: true,
              message: "请输入金额",
              trigger: "blur",
            },
          ],
          postUser: [
            {
              required: true,
              message: "请选择编报人",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.postUser == null || this.form.postUser == "") {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
        },
        formValidPass: true,
      };
    },
    props: {
      info: {
        type: Object,
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    components: { selectuser },
    mounted() {
      this.uploadFileUrl = request.getUploadUrl("document");
      this.getOrgTree();
      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        this.form.applyTime = this.$moment();
      }
    },
    methods: {
      changePrice(val) {
        if (val || val == 0) {
          let newVal = val.toString().replace(/[^0-9.]/g, "");
          this.$set(this.form, "price", newVal);
          this.form.priceCn = this.$util.menoyToUppercase(newVal);
        }
      },
      auditCb(data) {
        this.$emit("callback", data != null);
        this.getData();
      },
      gotoList() {
        this.$router.push({ path: "/business/incomebilllist" });
      },
      async showaudit() {
        let validRes = await this.$refs.loanbillform.validate();
        if (!validRes) {
          this.$message.error("请填写必填项");
          return;
        }
        this.formValidPass = validRes;
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      async auditCallBack(data) {
        let close = true;
        this.auditModal.visible = false;
        if (data != null && data.updateModel) {
          close = false;
          let validRes = await this.$refs.loanbillform.validate();
          if (!validRes) {
            return false;
          }
          if (this.fileList.length == 0) {
            this.$message.error("请上传附件");
            return false;
          }
          let attachList = [];
          this.fileList.forEach((item) => {
            attachList.push({
              attachName: item.name,
              attachPath: item.url,
            });
          });
          this.loading = true;
          this.loadingTip = "保存中...";
          let formData = {
            id: this.info.id,
            company: this.form.company,
            applyTime: this.form.applyTime,
            billNum: this.form.billNum,
            content: this.form.content,
            price: this.form.price,
            priceCn: this.form.priceCn,
            attachNum: this.form.attachNum,
            attachList: attachList,
            updateOnly: true,
          };
          formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
          let res = await incomebillApi.addOrUpdate(formData);
          if (res.errorCode == "0000") {
            this.loading = false;
            close = true;
          }
        }
        if (close) {
          this.$emit("callback", data != null);
        }
      },
      onSearch(record, type) {
        this.selectuseModal.visible = true;
        this.selectuseModal.record = record;
        this.selectuseModal.type = type;
      },
      selectuserCallback(user) {
        this.form.creatorName = user.realName;
        this.selectuseModal.visible = false;
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgDates = res.data.tree[0].children;
            res.data.tree[0].orgsNumber = this.orgsNumber;
            this.orgsNumber = this.orgsNumber++;
            this.orgs = res.data.tree;
            if (!this.data) {
              this.form.type = res.data.path;
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              for (var i = 0; i < res.data.path.length; ++i) {
                var id = res.data.path[i];
                var find = tree.filter((n) => n.id == id);
                if (i == res.data.path.length - 1) {
                  this.form.type = find[0].name;
                  this.form.orgName = find[0].name;
                } else {
                  tree = find[0].children;
                }
              }
            }
            this.form.creatorName = res.data.realName;
          }
        });
      },
      getData() {
        this.loading = true;
        incomebillApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            delete res.data.orgPath;
            res.data.applyTime = this.$moment(res.data.applyTime, "YYYY-MM-DD");
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.fileList = [];
            if (res.data.attachList) {
              res.data.attachList.forEach((item) => {
                this.fileList.push({
                  uid: item.id,
                  name: item.attachName,
                  url: item.attachPath,
                  status: "done",
                });
              });
            }
          }
        });
      },
      handleSubmit(status) {
        this.$refs.loanbillform.validate((valid) => {
          if (valid) {
            if (this.fileList.length == 0) {
              this.$message.error("请上传附件");
              return false;
            }
            let attachList = [];
            this.fileList.forEach((item) => {
              attachList.push({
                attachName: item.name,
                attachPath: item.url,
              });
            });
            let formData = { ...this.form, status, attachList };
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.auditRecords;
            formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
            //添加
            incomebillApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  if (this.modal) {
                    this.$emit("callback", true);
                  } else {
                    this.gotoList();
                  }
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      upLoadRemove() {
        return this.form.status == 0 || this.form.status == 3;
      },
      handleFileChange({ file, fileList }) {
        this.fileList = fileList;
        if (file.status == "uploading") {
        }
        if (file.status == "done") {
          fileList.forEach((item) => {
            if (item.response) {
              item.url = item.response.url;
            }
          });
          this.$message.success("上传成功");
        }
        if (file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      showWps() {
        let templateId = "income_bill";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;
        innerData.applyTime =
          innerData.applyTime == null
            ? ""
            : this.$moment(innerData.applyTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
        if (innerData.creatorPic != null && innerData.creatorPic != "") {
          innerData.creatorPic =
            "http://localhost:9000/" +
            innerData.creatorPic.substring(innerData.creatorPic.indexOf("uas"));
        }
        innerData.price = innerData.price.toFixed(2);
        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            let s = a.auditUserSignPicture.indexOf("uas");
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "收入票据整理单",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
