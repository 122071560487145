<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model :model="form" ref="postdataForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
        :class="{ disabled: form.status == 2 }">
        <table class="formtable">
          <tr>
            <td style="width: 100px">邮寄物名称</td>
            <td style="text-align: left" colspan="3">
              <a-form-model-item prop="name" style="margin: auto">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td style="width: 100px">部室</td>
            <td style="text-align: left">
              <a-cascader :options="orgs" v-model="form.orgId" @change="orgChanged" :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children',
              }" placeholder="请选择部室" />
            </td>
            <td>日期</td>
            <td style="text-align: left">
              <a-form-model-item prop="time" style="margin: auto">
                <a-date-picker v-model="form.time" valueFormat="YYYY-MM-DD" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>经办人</td>
            <td style="text-align: left">
              <a-input-search class="select_search" readOnly placeholder="请选择" v-model="form.handlerName"
                @click="onSearch" @search="onSearch">
                <a-button type="primary" slot="enterButton" icon="plus" title="添加" />
              </a-input-search>
            </td>
            <td>部室主任(签字)</td>
            <td style="text-align: left">
              <auditshow :infoData="form" taskKey="邮寄-部室主任(签字)" @callback="auditshowBack" />
            </td>
          </tr>
          <tr>
            <td colspan="4" style="border: none; padding: 0px">
              <a-table bordered rowKey="id" :columns="columns" :data-source="form.items" :pagination="false"
                :locale="{ emptyText: '暂无数据' }">
                <span slot="category" slot-scope="data">
                  <a-select v-model="data.category">
                    <a-select-option v-for="item in category" :value="item.itemValue" :key="item.itemValue">{{
                      item.itemName }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="count" slot-scope="data">
                  <a-input-number v-model="data.count" />
                </span>
                <span slot="remark" slot-scope="data">
                  <a-input v-model="data.remark" />
                </span>
                <span slot="way" slot-scope="data">
                  <a-select v-model="data.way">
                    <a-select-option v-for="item in way" :value="item.itemValue" :key="item.itemValue">{{ item.itemName
                    }}</a-select-option>
                  </a-select>
                </span>
                <span slot="action" slot-scope="text, row, index">
                  <a-popconfirm title="确定要删除吗？" @confirm="removeItem(index)">
                    <a-button size="small" type="link" v-if="form.status == 0 || form.status == 3">删除</a-button>
                  </a-popconfirm>
                </span>
                <template slot="footer" v-if="form.status == 0 || form.status == 3">
                  <a-button style="float: left" @click="addItem">
                    <a-icon type="plus" />
                    新增</a-button>
                  <span style="
                      color: red;
                      float: left;
                      margin-left: 10px;
                      line-height: 30px;
                    ">注：每一行为一个邮寄地址一个包裹，数量为每个包裹中的物品数量。</span>
                </template>
              </a-table>
            </td>
          </tr>
        </table>

      </a-form-model>
      <div style="text-align: center; margin-top: 10px">
        <a-button v-if="form.status == 0 || form.status == 3" type="primary" @click="handleSubmit(0)"
          style="margin-right: 10px">保存
        </a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)" v-if="form.status == 0 || form.status == 3">
          <a-button type="danger" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <a-button type="primary" v-if="form.processExecuteStatus && form.taskKey == '其他-审批'" style="margin-right: 5px"
          @click="showaudit()">审批
        </a-button>
        <a-button type="default" style="margin-right: 5px" v-if="this.form.status == 2" @click="showWps"
          class="cancelBtn">打印</a-button>
        <a-button type="default" @click="cancelClick" class="cancelBtn">取消
        </a-button>
        <a-button type="primary" v-if="form.status == 2 && allowFinish" style="margin-right: 5px"
          @click="finishTask()">办理</a-button>
      </div>
    </div>
    <a-modal title="选择用户" v-model="selectuservisible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
  import postdataApi from "@/api/work/postdata";
  import orgApi from "@/api/org";
  import dictionaryItemApi from "@/api/system/dictionaryItem";
  import selectuser from "../components/searchuser.vue";
  import commons from "@/utils/commons.js";
  import pinyin from "js-pinyin";
  import workflowApi from "@/api/workflow";
  import workflowapi from "@/api/workflow";

  const columns = [
    {
      key: "index",
      align: "center",
      title: "序号",
      width: 70,
      customRender: (text, record, index) => {
        return index + 1;
      },
    },
    {
      key: "category",
      align: "center",
      title: "邮寄物种类",
      scopedSlots: { customRender: "category" },
    },
    {
      key: "count",
      align: "center",
      title: "数量（每个包裹中的物品数量）",
      scopedSlots: { customRender: "count" },
    },
    {
      key: "way",
      align: "center",
      title: "邮寄方式",
      scopedSlots: { customRender: "way" },
    },
    {
      key: "remark",
      align: "center",
      title: "备注(必填，请填写具体收件单位名称)",
      scopedSlots: { customRender: "remark" },
    },

    {
      title: "操作",
      key: "action",
      width: 120,
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  export default {
    name: "createpostdata",
    data() {
      return {
        loading: false,
        form: {
          time: null,
          status: 0,
          items: [{}],
        },
        taskId: "",
        allowFinish: false,
        columns,
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
        rules: {
          name: [
            {
              required: true,
              message: "请输入邮寄物名称",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (value == null || value == "") {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          time: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
        },
        orgs: [],
        category: [],
        way: [],
        selectuservisible: false,
        auditModal: {
          info: null,
          visible: false,
        },
        allowAction: false,
      };
    },
    props: {
      info: {
        type: Object,
      },
      data: {
        type: Object,
      },
    },
    mounted() {
      this.getOrgTree();
      this.getDic();
      if (this.info && this.info.id > 0) {
        this.getData();
      } else {
        this.form.time = this.$moment();
      }
    },
    components: {
      selectuser,
    },
    methods: {
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },

      async getDic() {
        var d1 = dictionaryItemApi.getItemByDictionaryName("邮寄物种类");
        var d2 = dictionaryItemApi.getItemByDictionaryName("邮寄方式");
        var d1r = await d1;
        var d2r = await d2;
        if (d1r.errorCode == this.$commons.RespCode.success) {
          this.category = d1r.data;
        }
        if (d2r.errorCode == this.$commons.RespCode.success) {
          this.way = d2r.data;
        }
      },
      orgChanged(v, selectedOptions) {
        if (selectedOptions == null || selectedOptions.length == 0) {
          this.form.orgName = null;
          this.form.orgId = [];
        } else {
          this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
          this.form.orgId = v;
        }
        this.$forceUpdate();
      },
      onSearch() {
        this.selectuservisible = true;
      },
      selectuserCallback(user) {
        this.form.handler = user.userName;
        this.form.handlerName = user.realName;
        this.selectuservisible = false;
      },
      addItem() {
        this.form.items.push({});
      },
      removeItem(index) {
        this.form.items.splice(index, 1);
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgs = res.data.tree;
            if (!this.info) {
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              if (res.data.path) {
                for (var i = 0; i < res.data.path.length; ++i) {
                  var id = res.data.path[i];
                  var find = tree.filter((n) => n.id == id);
                  if (i == res.data.path.length - 1) {
                    this.form.orgName = find[0].name;
                  } else {
                    tree = find[0].children;
                  }
                }
              }

              this.form.handlerName = res.data.realName;
              this.form.handler = localStorage.getItem(
                this.$commons.User.userName
              );
            }
          }
        });
      },
      getData() {
        this.loading = true;
        postdataApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            delete res.data.orgPath;
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.allowAction =
              this.form.taskUsers == localStorage.getItem(commons.User.userName);
            this.getTask(res.data.processId);
          }
        });
      },
      async finishTask() {
        if (this.taskId == "" || this.taskId == null) {
          this.$message.error("已办理");
          return;
        }
        this.worktaskloading = true;
        var res = await workflowApi.finishTask(this.taskId);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      getTask(id) {
        this.loading = true;
        this.taskId = "";
        this.allowFinish = false;
        workflowapi.getTask(id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data != null && res.data != "" && res.data.length > 0) {
              this.taskId = res.data[0].id;
              this.allowFinish = true;
              this.$forceUpdate();
            }
          }
        });
      },
      handleSubmit(status) {
        this.$refs.postdataForm.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            this.loading = true;
            if (this.info) {
              formData.id = this.info.id;
            }
            delete formData.auditRecords;
            for (let i = 0; i < formData.items.length; i++) {
              if (
                formData.items[i].category == null ||
                formData.items[i].category == ""
              ) {
                this.$message.warning("邮寄物种类不能为空！");
                this.loading = false;
                return false;
              }
              if (
                formData.items[i].count == null ||
                formData.items[i].count == ""
              ) {
                this.$message.warning("数量不能为空！");
                this.loading = false;
                return false;
              }
              if (formData.items[i].way == null || formData.items[i].way == "") {
                this.$message.warning("邮寄方式不能为空！");
                this.loading = false;
                return false;
              }
              if (
                formData.items[i].remark == null ||
                formData.items[i].remark == ""
              ) {
                this.$message.warning("备注不能为空！");
                this.loading = false;
                return false;
              }
            }
            //添加
            postdataApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  this.$emit("callback", true);
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      auditshowBack() {
        this.getData();
        this.$emit("callback", false);
      },
      showWps() {
        let templateId = "postdata";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;
        innerData.notInsertTableRow = "true";
        innerData.time =
          innerData.time == null
            ? ""
            : this.$moment(innerData.time, "YYYY-MM-DD").format("YYYY年MM月DD日");
        if (this.form.items != null && this.form.items.length > 0) {
          let i = 0;
          this.form.items.forEach((a) => {
            a.sort = ++i;
          });
          delete innerData.items;
          innerData.list = this.form.items;
        }

        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "邮寄服务",
            tempUrl: "writeNumberTemp",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
