<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body">
          <a-button type="primary" v-if="form.id && form.id > 0 && form.status == 2"
            @click="gototravelexpense">报销</a-button>
          <a-button style="float: right" @click="gotoList">返回</a-button>
        </div>
      </div>
      <div style="text-align: center; font-size: 40px">出差申请</div>
      <div class style="position: relative; text-align: center">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
          style="width: 70%; padding: 30px; margin: 0 20% 0 10%" :class="{ disabled: form.status == 2 }">
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="出差人" prop="outUserName">
                <a-input v-model="form.outUserName" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="部室" prop="org">
                <a-cascader :options="orgs" placeholder="请选择部室" @change="orgChanged" :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }" v-model="form.orgId" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="时间" prop="time">
                <a-range-picker v-model="form.time" @change="timeChanged" style="width: 100%"></a-range-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="出差天数" prop="days">
                <a-input placeholder="请输入出差天数" v-model="form.days"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" style="text-align: left">
              <a-form-model-item label="职务" prop="duties">
                <a-select v-model="form.duties" placeholder="请选择" @change="roleselected">
                  <template v-for="tag in roleAll">
                    <a-select-option :value="tag.name" :key="tag.id">
                      {{ tag.name }}
                    </a-select-option>
                  </template>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="12" style="text-align:left">
            <a-form-model-item label="陪同人员" props="accompany">
              <a-input-search  v-model="form.accompanyName" @search="onSearch('accompany')" />
           </a-form-model-item>
           </a-col> -->
          </a-row>
          <a-row>
            <a-col :span="24" style="text-align: left">
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" label="出差事由" prop="description">
                <a-textarea placeholder="请输入出差事由" v-model="form.description" style="height: 100px"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div style="text-align: center; margin-top: 10px">
          <a-button type="primary" v-if="form.status == 0 || form.status == 3" style="margin-right: 10px"
            @click="handleSubmit(0)">保存</a-button>
          <a-popconfirm v-if="form.status == 0 || form.status == 3" title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)">
            <a-button type="danger" style="margin-right: 10px">提交</a-button>
          </a-popconfirm>

          <a-button type="primary" v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
            style="margin-right: 10px" @click="showaudit()">审批</a-button>
          <a-button v-if="this.form.status == 2" @click="showWps" style="margin-right: 10px">打印</a-button>
          <a-button v-if="modal" @click="cancelClick">取消</a-button>
        </div>
      </div>
    </div>
    <a-modal title="选择用户" v-model="selectuseModal.visible" width="70%" :footer="false" destroyOnClose
      :maskClosable="false">
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>

<script>
  import orgApi from "@/api/org";
  import roleApi from "@/api/role";
  import selectuser from "../components/searchuser.vue";
  import officialoutApi from "@/api/work/officialout";
  import commons from "@/utils/commons";
  import pinyin from "js-pinyin";
  export default {
    props: {
      info: {
        type: Object,
        default: () => { },
      },
      modal: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        roleAll: [],
        orgs: [],
        form: {
          status: 0,
        },
        zhiwu: [],
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
        isloading: false,
        loadingTip: "加载中...",
        selectuseModal: {
          visible: false,
          record: null,
        },
        auditModal: {
          info: null,
          visible: false,
        },
        rules: {
          outUserName: [
            {
              required: true,
              message: "请选择出差人",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.outUser == null || this.form.outUser == "") {
                  callback(new Error(""));
                  return;
                }
                callback();
              },
            },
          ],
          // accompany: [
          //   {
          //     required: false,
          //     message: '请选择陪同人员',
          //     trigger: 'blur',
          //     validator: (rule, value, callback) => {
          //       if (
          //         this.form.accompany != null &&
          //         this.form.accompany != '' &&
          //         (this.form.accompanyName == null ||
          //           this.form.accompanyName == '')
          //       ) {
          //         callback(new Error(''));
          //         return;
          //       }
          //       callback();
          //     }
          //   }
          // ],
          org: [
            {
              required: true,
              message: "请选择部室",
              trigger: "blur",
              validator: (rule, value, callback) => {
                if (this.form.orgId.length == 0) {
                  callback(new Error("请选择部室"));
                  return;
                }
                callback();
              },
            },
          ],
          time: [
            {
              required: true,
              message: "请选择出差时间",
              trigger: "blur",
            },
          ],
          days: [
            {
              required: true,
              message: "请输入出差天数",
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: "请输入出差事由",
              trigger: "blur",
            },
          ],
        },
        currUser: localStorage.getItem(this.$commons.User.userName),
      };
    },
    watch: {
      info: {
        handler(newValue, oldValue) {
          if (newValue.id == null || newValue.id == 0)
            localStorage.setItem("officialoutinfo", JSON.stringify(newValue));
        },
        deep: true,
      },
    },
    mounted() {
      this.$nextTick(function () {
        if (this.info) {
          this.getData();
        } else {
          var item = JSON.parse(localStorage.getItem("officialoutinfo"));
          var flag = false;
          for (var key in item) {
            if (key != "status") {
              flag = true;
              break;
            }
          }
          if (flag) {
            this.form = item;
            if (this.form.startTime) {
              this.form.startTime = this.$moment(this.form.startTime);
              this.form.endTime = this.$moment(this.form.endTime);
              this.form.time = [this.form.startTime, this.form.endTime];
            }
          } else {
            var params = this.$route.params;
            if (params && params.status != null) {
              this.form = params;
            } else {
              this.getLast();
            }
          }
        }
        // this.getDic()
        this.getOrgTree();
        // this.getRoleAll();
      });
    },
    components: {
      selectuser,
    },
    methods: {
      roleselected(v) {
        // this.info.roleIds=v;
        this.$forceUpdate();
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      getLast() {
        officialoutApi.getLastForUser().then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            res.data.startTime = this.$moment(res.data.startTime, "YYYY-MM-DD");
            res.data.endTime = this.$moment(res.data.endTime, "YYYY-MM-DD");
            delete res.data.orgPath;
            this.form = res.data;
            this.form.time = [
              this.$moment(res.data.startTime, "YYYY-MM-DD"),
              this.$moment(res.data.endTime, "YYYY-MM-DD"),
            ];
          }
        });
      },
      createnew() {
        this.form = { status: 0 };
        this.getOrgTree();
        localStorage.removeItem("officialoutinfo");
      },
      // async getDic(){
      //     var d1r =await dictionaryItemApi.getItemByDictionaryName("职务");
      //     if (d1r.errorCode == this.$commons.RespCode.success) {
      //         this.zhiwu=d1r.data;
      //     }
      //
      // },
      gototravelexpense() {
        if (this.form.id == null || this.form.id == 0) {
          this.$message.error("当前信息没有提交，不能执行报销");
          return;
        }

        this.$router.push({ path: "/business/travelexpense" });
      },
      getData() {
        this.loading = true;
        officialoutApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.orgId = res.data.orgPath;
            res.data.startTime = this.$moment(res.data.startTime, "YYYY-MM-DD");
            res.data.endTime = this.$moment(res.data.endTime, "YYYY-MM-DD");
            delete res.data.orgPath;
            this.form = res.data;
            this.form.time = [
              this.$moment(res.data.startTime, "YYYY-MM-DD"),
              this.$moment(res.data.endTime, "YYYY-MM-DD"),
            ];
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
          }
        });
      },
      gotoList() {
        this.$router.push({ path: "/business/officialout" });
      },
      orgChanged(v) {
        this.form.orgId = v;
        this.$forceUpdate();
      },
      onSearch(record) {
        this.selectuseModal.visible = true;
        this.selectuseModal.record = record;
      },
      selectuserCallback(user) {
        if (this.selectuseModal.record == "outUserName") {
          this.form.outUser = user.userName;
          this.form.outUserName = user.realName;
        } else {
          this.form.accompany = user.userName;
          this.form.accompanyName = user.realName;
        }

        this.selectuseModal.visible = false;
      },
      timeChanged(times) {
        this.form.startTime = times[0];
        this.form.endTime = times[1];
        var duration = this.$moment.duration(
          this.form.endTime - this.form.startTime
        );
        this.form.days = duration.days() + 1;
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgs = res.data.tree;
            if (!this.info) {
              this.form.orgId = res.data.path;
              let tree = this.orgs;
              for (var i = 0; i < res.data.path.length; ++i) {
                var id = res.data.path[i];
                var find = tree.filter((n) => n.id == id);
                if (i == res.data.path.length - 1) {
                  this.form.orgName = find[0].name;
                } else {
                  tree = find[0].children;
                }
              }
              this.form.outUserName = res.data.realName;
              this.form.outUser = localStorage.getItem(
                this.$commons.User.userName
              );
              this.getUserRolesByName(this.form.outUser);
              this.$forceUpdate();
            }
          }
        });
      },
      getRoleAll() {
        roleApi
          .getRolesAll()
          .then((res) => {
            if (res.errorCode == this.$commons.RespCode.success) {
              this.roleAll = res.data;
            } else {
              this.$message.error(res.errorMsg);
            }

            this.isloading = false;
          })
          .catch(() => {
            this.isloading = false;
          });
      },
      getUserRolesByName(userName) {
        roleApi.getUserRolesByName(userName).then((res) => {
          if (res.errorCode == "0000") {
            this.roleAll = res.data.filter((n) => n.name != "超级管理员");
            if (this.form.duties == "" || this.form.duties == null) {
              if (this.roleAll.length != null && this.roleAll.length > 0)
                this.form.duties = this.roleAll[0].name;
            }
          }
        });
      },
      handleSubmit(status) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.startTime = formData.startTime.format("YYYY-MM-DD HH:mm:ss");
            formData.endTime = formData.endTime.format("YYYY-MM-DD HH:mm:ss");
            formData.orgId = formData.orgId[formData.orgId.length - 1];
            delete formData.time;
            delete formData.auditRecords;
            this.loading = true;
            this.loadingTip = "保存中...";
            if (this.info) {
              formData.id = this.info.id;
            }
            let ss = localStorage.getItem(commons.User.userName);
            if (ss != null) {
              roleApi.getUserRolesByName(ss).then((res) => {
                if (res.errorCode == "0000") {
                  let roles = new Array();
                  for (let i = 0; i < res.data.length; i++) {
                    roles.push(res.data[i].name);
                  }
                  formData.user_roles = roles.toString();
                  //添加
                  officialoutApi
                    .addOrUpdate(formData)
                    .then((res) => {
                      if (res.errorCode == this.$commons.RespCode.success) {
                        this.$message.success(res.errorMsg);
                        localStorage.removeItem("officialoutinfo");
                        if (this.modal) {
                          this.$emit("callback", true);
                        } else {
                          this.gotoList();
                        }
                      } else {
                        this.$message.error(res.errorMsg);
                      }
                      this.loading = false;
                    })
                    .catch(() => {
                      this.loading = false;
                    });
                }
              });
            } else {
              this.$message.error("请重新登陆");
            }
          } else {
            return false;
          }
        });
      },
      showWps() {
        let templateId = "officialout";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.status;
        delete innerData.creatorOrgId;

        innerData.createTime = this.$moment(
          innerData.createTime,
          "YYYY-MM-DD"
        ).format("YYYY年MM月DD日");
        innerData.time =
          innerData.startTime.format("YYYY年MM月DD日") +
          " 至 " +
          innerData.endTime.format("YYYY年MM月DD日");

        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey." + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
          if (a.taskPrintKey != null) {
            key = "taskKey." + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let task = {
            auditRemark: a.auditRemark,
            pic: a.auditUserSignPicture,
            name: a.auditUserName,
            time: this.$moment(a.time, "YYYY-MM-DD").format("YYYY年MM月DD日"),
          };
          innerData[key].push(task);
        });

        if (innerData.duties.indexOf("院长") == 0) templateId = "officialoutYuan";

        orgApi.getSignPictures([this.form.creator]).then((res) => {
          let ottask = {
            auditRemark: this.form.description,
            pic: res[this.form.creator],
            name: this.form.outUserName,
            time: this.$moment(this.form.createTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData["taskKey.QingJiaRen"] = [];
          innerData["taskKey.QingJiaRen"].push(ottask);
          localStorage.setItem("innerData", JSON.stringify(innerData));
          localStorage.removeItem("publicFileId");

          const jump = this.$router.resolve({
            name: "wpsedit",
            query: {
              //要传的参数
              templateId: templateId,
              fileType: "public",
              openType: "create",
              fileName: "出差",
            },
          });

          window.open(jump.href, "_blank");
        });
      },
    },
  };
</script>
<style></style>
