<template>
  <div style="background-color: #f0f2f5; height: 100%">
    <a-row :gutter="10" style="height: 100%">
      <a-col :span="13">
        <div style="background-color: #fff; padding: 20px; border-radius: 10px">
          <h1 style="font-size: 16px">
            我的待办
            <span v-if="works.total > 0">(<font color="red">{{ works.total }}</font>)</span>
          </h1>
          <a-list item-layout="horizontal" :loading="worktaskloading" :data-source="works.records">
            <a-list-item slot="renderItem" slot-scope="item">
              <template slot="actions" v-if="item.status != 2">
                <a-button type="link" v-if="item.waitResult == null || item.waitResult == 1"
                  @click="showprocess(item)">{{ item.taskKey.indexOf("会签") != -1 ? '待签字' : '待审批' }}</a-button>
                <a-button type="link" v-else @click="showprocess(item)">待办理</a-button>
              </template>
              <a-list-item-meta :description="item.businessRemark">
                <span slot="title" style="font-weight: bold">{{
                  item.businessTitle
                }}</span>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <a-pagination size="small" :pageSize="5" @change="taskpagechanged"
            style="margin-top: 20px; text-align: center" :total="works.total"
            :show-total="(total) => `总数 ${works.total} 条`" />
        </div>
        <div style="
            background-color: #fff;
            padding: 20px;
            margin-top: 10px;
            border-radius: 10px;
          ">
          <h1 style="font-size: 16px">
            消息提醒
            <span v-if="message.total > 0">(<font color="red">{{ message.total }}</font>)</span>
          </h1>
          <a-list item-layout="horizontal" :loading="messageloading" :data-source="message.records">
            <a-list-item slot="renderItem" slot-scope="item">
              <template slot="actions">
                <a-button type="link" @click="readed(item.id)">已读</a-button>
              </template>
              <a-list-item-meta :description="'内容:' + item.body">
                <a slot="title" style="font-weight: bold">标题:{{ item.title ? item.title : "无" }}</a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </a-col>
      <a-col :span="11">
        <div style="background-color: #fff; padding: 20px; border-radius: 10px">
          <h1 style="font-size: 16px">我的流程</h1>
          <a-list item-layout="horizontal" :loading="processmodeluser.loading" :data-source="processmodeluser.records">
            <a-list-item slot="renderItem" slot-scope="item">
              <template slot="actions">
                <processStatus :processModel="{
                  status: 1,
                  currentNode: item.currentNodeId,
                  currentNodeName: item.currentNodeName,
                  processId: item.id,
                  processModelKey: item.processModelKey,
                  id: item.businessId,
                }" />
              </template>
              <a-list-item-meta :description="item.businessRemark">
                <span slot="title" style="font-weight: bold">{{
                  item.businessTitle
                }}</span>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <a-pagination size="small" :pageSize="5" @change="pagechanged" style="margin-top: 20px; text-align: center"
            :total="processmodeluser.total" :show-total="(total) => `总数 ${processmodeluser.total} 条`" />
        </div>
      </a-col>
    </a-row>
    <a-modal title="详情" v-model="newsdetailmodal.visible" width="1100px" :footer="false" destroyOnClose
      :maskClosable="false">
      <newsdetail @callback="closenewsmodal" :id="newsdetailmodal.id" />
    </a-modal>
    <a-modal title="任务处理" v-model="processmodal.visible" width="85%" :footer="false" destroyOnClose
      :maskClosable="false" @cancel="closeprocess">
      <component v-bind:is="processmodal.processModelKey + 'info'" :info="processmodal.info" :data="processmodal"
        :modal="true" @callback="closeprocess"></component>
    </a-modal>
  </div>
</template>
<script>
  import commons from "@/utils/commons.js";
  import workflowApi from "@/api/workflow";
  import messageApi from "@/api/message";
  import newsApi from "@/api/cms/newsArticle";
  import newsdetail from "./newsdetail.vue";
  import { mapActions } from "vuex";

  import newsarticleinfo from "@/views/cms/NewsArticleInfo.vue";
  import officialoutinfo from "@/views/business/personal/officialoutinfo";
  import businessoutinfo from "@/views/business/personal/businessoutinfo";
  import leaveapplicationinfo from "@/views/business/personal/leaveapplicationinfo.vue";
  import annualbudgetinfo from "@/views/business/finance/annualbudget";
  import loanbillinfo from "@/views/business/finance/loanbill";
  import publicbudgetinfo from "@/views/business/finance/publicbudget";
  import specialexpenditureinfo from "@/views/business/finance/specialexpenditure";
  import specialfundinfo from "@/views/business/finance/specialfund";
  import travelexpenseinfo from "@/views/business/finance/travelexpense";
  import documentprintinfo from "@/views/business/document/createdocumentprint";
  import documentreadinfo from "@/views/business/document/createdocumentread";
  import assetapplyinfo from "@/views/business/asset/assetapply";
  import assetpurchaseinfo from "@/views/business/asset/assetpurchase";
  import assetrepairinfo from "@/views/business/asset/assetrepair";
  import faweninfo from "@/views/business/dispatch/fawen.vue";
  import qingshiinfo from "@/views/business/dispatch/qingshi.vue";
  import huiyijiyaoinfo from "@/views/business/dispatch/huiyijiyao.vue";
  import zhibugongzuoinfo from "@/views/business/dispatch/zhibugongzuo.vue";
  import postdatainfo from "@/views/business/personal/createpostdata.vue";
  import bookcarinfo from "@/views/business/finance/bookcarinfo.vue";
  import officialapprovalinfo from "@/views/business/finance/officialapprovalinfo.vue";
  import specialexpenditureexpendinfo from "@/views/business/finance/specialexpenditureExpend";
  import escrowfundbudgetinfo from "@/views/business/finance/escrowfundbudget.vue";
  import publicbillinfo from "@/views/business/finance/publicbill.vue";
  import incomebillinfo from "@/views/business/finance/incomebill.vue";
  import websiteinfopublishinfo from "@/views/business/document/websiteinfopublish.vue";

  export default {
    name: "workhome",
    data() {
      return {
        userName: localStorage.getItem(commons.User.userName),
        works: {
          total: 0,
          records: [],
          current: 1,
        },
        message: {
          total: 0,
          records: [],
        },
        news: [],
        newsdetailmodal: {
          visible: false,
          id: 0,
        },
        processmodal: {
          visible: false,
          title: "",
          info: null,
          processModelKey: "",
        },
        processmodeluser: {
          loading: false,
          records: [],
          total: 0,
          current: 1,
        },
        messageloading: false,
        worktaskloading: false,
        newsloading: false,
        rootSubmenuKeys: ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6"],
        openKeys: ["sub1"],
      };
    },
    mounted() {
      this.getWorks();
      this.getMessage();
      this.getProcessModels();
    },
    components: {
      newsdetail,
      newsarticleinfo,
      officialoutinfo,
      businessoutinfo,
      leaveapplicationinfo,
      annualbudgetinfo,
      loanbillinfo,
      publicbudgetinfo,
      specialexpenditureinfo,
      specialfundinfo,
      travelexpenseinfo,
      documentprintinfo,
      documentreadinfo,
      assetapplyinfo,
      assetpurchaseinfo,
      assetrepairinfo,
      faweninfo,
      qingshiinfo,
      huiyijiyaoinfo,
      zhibugongzuoinfo,
      postdatainfo,
      bookcarinfo,
      specialexpenditureexpendinfo,
      officialapprovalinfo,
      escrowfundbudgetinfo,
      publicbillinfo,
      incomebillinfo,
      websiteinfopublishinfo
    },
    computed: {
      messageCountState() {
        return this.$store.state.systemMenu.messageCount;
      },
    },
    watch: {
      messageCountState(val) {
        if (val != this.message.total) {
          this.getMessage();
        }
      },
    },
    methods: {
      ...mapActions({
        stateWorkCount: "systemMenu/setWorkCount",
        stateMessageCount: "systemMenu/setMessageCount",
      }),
      closeprocess() {
        this.processmodal.visible = false;
        this.getWorks();
      },
      showprocess(item) {
        this.processmodal.info = { id: item.businessId };
        var t = item.processModelKey.indexOf(":");
        if (t != -1) {
          var v = item.processModelKey.substring(t + 1);
          if (
            v == "请示" ||
            v == "报告" ||
            v.indexOf("通知") != -1 ||
            v == "党支部请示"
          ) {
            this.processmodal.processModelKey = "qingshi";
          } else if (v == "会议纪要") {
            this.processmodal.processModelKey = "huiyijiyao";
          } else if (v == "支部工作动态") {
            this.processmodal.processModelKey = "zhibugongzuo";
          } else {
            this.processmodal.processModelKey = "fawen";
          }
        } else {
          this.processmodal.processModelKey = item.processModelKey;
        }
        this.processmodal.visible = true;
      },
      closenewsmodal() {
        this.newsdetailmodal.visible = false;
      },
      shownewsdetail(id) {
        this.newsdetailmodal.id = id;
        this.newsdetailmodal.visible = true;
      },
      onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(
          (key) => this.openKeys.indexOf(key) === -1
        );
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
      async getNews() {
        this.newsloading = true;
        var res = await newsApi.pageList(1, 10, { categoryName: "通知公告" });
        this.newsloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.news = res.data.records;
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      pagechanged(page) {
        this.processmodeluser.current = page;
        this.getProcessModels();
      },
      taskpagechanged(page) {
        this.works.current = page;
        this.getWorks();
      },
      async finishTask(item) {
        this.worktaskloading = true;
        var res = await workflowApi.finishTask(item.id);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getWorks();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      async getProcessModels() {
        this.processmodeluser.loading = true;
        var res = await workflowApi.getProcessForUser(
          this.processmodeluser.current,
          5
        );
        this.processmodeluser.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.processmodeluser.records = res.data.records;
          this.processmodeluser.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      async getWorks() {
        this.worktaskloading = true;
        var res = await workflowApi.getTaskForUser(this.works.current, 5);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.works = res.data;
          this.stateWorkCount(Number(res.data.total));
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      readed(id) {
        messageApi.read(id).then((res) => {
          if (res.errorCode === this.$commons.RespCode.success) {
            this.getMessage();
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      },
      async getMessage() {
        this.messageloading = true;
        var res = await messageApi.getForUser(1, 5, 1);
        this.messageloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.message = res.data;
          this.stateMessageCount(Number(res.data.total));
        } else {
          this.$message.error(res.errorMsg);
        }
      },
    },
  };
</script>
